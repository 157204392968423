import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const Informazioni = () => {
  return (
    <Box sx={{ position: 'relative', overflow: 'hidden', height: '100vh' }}>
      <Container

      >
        <Typography variant="h2" component="h1" gutterBottom>
          Magazzino Acciona
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Interfaccia di amministrazione degli scarichi di magazzino inviati da app mobile.
        </Typography>
        <div>
          <h1>Scarica la App Android</h1>
          <p>Clicca per scaricare la app Android</p>
          <a href="http://5.249.157.198/downloads/app-release.apk" download>
            <button>Scarica</button>
          </a>
        </div>
      </Container>
    </Box>
  )
}

export default Informazioni