import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import AuthService from "../services/auth.service";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Snackbar,
    TextField,
    ThemeProvider,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import {
    getAllDepositi,
    getDepositoById,
    addDeposito,
    updateDeposito,
    deleteDeposito,
} from "../services/ScarichiService";
import {
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_IT } from "material-react-table/locales/it";
import { Delete, Edit } from "@mui/icons-material";
// SweetAlert - 20/06/2024
import Swal from "sweetalert2";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/it";

const Depositi = () => {
    const [listaDepositi, setListaDepositi] = useState([]);
    const [depositoSelezionato, setDepositoSelezionato] = useState({});
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    // per il nuovo deposito - C. Carlucci 19/02/2025
    const [openDlgNuovo, setOpenDlgNuovo] = useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState("md");
    // stati per il nuovo deposito - C. Carlucci 19/02/2025
    const [codiceDeposito, setCodiceDeposito] = React.useState("");
    const [descrizioneDeposito, setDescrizioneDeposito] = React.useState("");
    const [codiceDepositoPadre, setCodiceDepositoPadre] = React.useState("");
    const [indirizzoCoordinate, setIndirizzoCoordinate] = React.useState("");
    // stati per la modifica deposito - C. Carlucci 19/02/2025
    const [codiceDepositoModifica, setCodiceDepositoModifica] =
        React.useState("");
    const [descrizioneDepositoModifica, setDescrizioneDepositoModifica] =
        React.useState("");
    const [codiceDepositoPadreModifica, setCodiceDepositoPadreModifica] =
        React.useState("");
    const [indirizzoCoordinateModifica, setIndirizzoCoordinateModifica] =
        React.useState("");

    useEffect(() => {
        getAllDepositi().then((response) => {
            setListaDepositi(response);
        });
    }, []);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenDlgNuovo(false);
    };



    const handleNuovoDeposito = () => {

        if (codiceDeposito === "") {
            Swal.fire({
                title: "Errore",
                text: "Il campo Codice deposito è obbligatorio!",
                icon: "error",
                confirmButtonText: "Chiudi",
            });
            setOpenDlgNuovo(false);
            return;
        }

        if (descrizioneDeposito === "") {
            Swal.fire({
                title: "Errore",
                text: "Il campo Descrizione deposito è obbligatorio!",
                icon: "error",
                confirmButtonText: "Chiudi",
            });
            setOpenDlgNuovo(false);
            return;
        }

        if (codiceDepositoPadre === "") {
            Swal.fire({
                title: "Errore",
                text: "Il campo Codice deposito padre è obbligatorio!",
                icon: "error",
                confirmButtonText: "Chiudi",
            });
            setOpenDlgNuovo(false);
            return;
        }

        if (indirizzoCoordinate === "") {
            Swal.fire({
                title: "Errore",
                text: "Il campo Indirizzo coordinate è obbligatorio!",
                icon: "error",
                confirmButtonText: "Chiudi",
            });
            setOpenDlgNuovo(false);
            return;
        }

        // Verifica esistenza codice deposito da inserire - C. Carlucci 05/03/2025
        const depositoEsistente = listaDepositi.find(
            (dep) => dep.codice_deposito === codiceDeposito
        );
        if (depositoEsistente) {
            Swal.fire({
                title: "Errore",
                text: "Il codice deposito " + codiceDeposito + " esiste già!",
                icon: "error",
                confirmButtonText: "Chiudi",
            });
            setOpenDlgNuovo(false);
            return;
        }

        const newDeposito = {
            codice_deposito: codiceDeposito,
            descrizione_deposito: descrizioneDeposito,
            codice_deposito_padre: codiceDepositoPadre,
            indirizzo_coordinate: indirizzoCoordinate,
        };
        console.log(
            "newDeposito che invio a inserisciDeposito contiene " +
            JSON.stringify(newDeposito)
        );

        addDeposito(newDeposito).then((response) => {
            console.log("Nuovo Deposito creato: ", response);
            setCodiceDeposito("");
            setDescrizioneDeposito("");
            setCodiceDepositoPadre("");
            setIndirizzoCoordinate("");

            setOpenDlgNuovo(false);
            getAllDepositi().then((response) => {
                setListaDepositi(response);
            });

            Swal.fire({
                title: "Deposito aggiunto",
                text: "Il nuovo deposito è stato aggiunto con successo!",
                icon: "success",
                confirmButtonText: "Chiudi",
            });
        });
    };

    const handleAnnullaNuovo = () => {
        setCodiceDeposito("");
        setDescrizioneDeposito("");
        setCodiceDepositoPadre("");
        setIndirizzoCoordinate("");
        setOpenDlgNuovo(false);
    };

    const handleEditRow = (row) => {
        let id = row.original.id;
        getDepositoById(id).then((response) => {
            setDepositoSelezionato((prevState) => ({
                ...prevState,
                ...response,
            }));
            // Extract and map the roles to your custom key names

            setCodiceDepositoModifica(response.codice_deposito);
            setDescrizioneDepositoModifica(response.descrizione_deposito);
            setCodiceDepositoPadreModifica(response.codice_deposito_padre);
            setIndirizzoCoordinateModifica(response.indirizzo_coordinate);

            console.log("depositoSelezionato è " + JSON.stringify(response));
            setOpenDialog(true);
        });
    };

    const handleDeleteRow = useCallback(
        (row) => {
            console.log("row contiene " + JSON.stringify(row));
            if (
                // eslint-disable-next-line no-restricted-globals
                !confirm(
                    `Sei sicuro di voler cancellare il deposito n. ${row.getValue("codice_deposito")}`
                )
            ) {
                return;
            }
            //send api delete request here, then refetch or update local table data for re-render
            deleteDeposito(row.original.id).then((response) => {
                console.log("response di eliminaDeposito " + response);
                /*  setSeverity("success");
                 setMessage("Utente eliminato!"); */
                Swal.fire({
                    title: "Deposito eliminato",
                    text: "Il tuo deposito è stato eliminato con successo!",
                    icon: "success",
                    confirmButtonText: "Chiudi",
                })

                getAllDepositi().then((response) => {
                    setListaDepositi(response);
                });
            });
        },

        []
    );

    const handleModificaUtente = () => {
        if (codiceDepositoModifica === "") {
            Swal.fire({
                title: "Errore",
                text: "Il campo Codice deposito è obbligatorio!",
                icon: "error",
                confirmButtonText: "Chiudi",
            });
            setOpenDialog(false);

            return;
        }

        if (descrizioneDepositoModifica === "") {
            Swal.fire({
                title: "Errore",
                text: "Il campo Descrizione deposito è obbligatorio!",
                icon: "error",
                confirmButtonText: "Chiudi",
            });
            setOpenDialog(false);

            return;
        }

        if (codiceDepositoPadreModifica === "") {
            Swal.fire({
                title: "Errore",
                text: "Il campo Codice deposito padre è obbligatorio!",
                icon: "error",
                confirmButtonText: "Chiudi",
            });
            setOpenDialog(false);

            return;
        }

        if (indirizzoCoordinateModifica === "") {
            Swal.fire({
                title: "Errore",
                text: "Il campo Indirizzo coordinate è obbligatorio!",
                icon: "error",
                confirmButtonText: "Chiudi",
            });
            setOpenDialog(false);

            return;
        }



        const updatedDeposito = {
            codice_deposito: codiceDepositoModifica,
            descrizione_deposito: descrizioneDepositoModifica,
            codice_deposito_padre: codiceDepositoPadreModifica,
            indirizzo_coordinate: indirizzoCoordinateModifica,
        };
        console.log(
            "updatedDeposito che invio a updateDeposito contiene " +
            JSON.stringify(updatedDeposito)
        );
        updateDeposito(depositoSelezionato.id, updatedDeposito).then(
            (response) => {
                console.log("Deposito modificato: ", response);
                setCodiceDepositoModifica("");
                setDescrizioneDepositoModifica("");
                setCodiceDepositoPadreModifica("");
                setIndirizzoCoordinateModifica("");
                setOpenDialog(false);
                getAllDepositi().then((response) => {
                    setListaDepositi(response);
                });
                Swal.fire({
                    title: "Deposito modificato",
                    text: "Il deposito è stato modificato con successo!",
                    icon: "success",
                    confirmButtonText: "Chiudi",
                });
            }
        );
    };

    const handleCloseEdit = () => {
        setOpenDialog(false);
    };


    const depositiColumns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "ID",
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
            },
            {
                accessorKey: "codice_deposito",
                header: "Codice Deposito",
                enableColumnOrdering: true,
                enableEditing: true,
                enableSorting: true,
                size: 100,
            },
            {
                accessorKey: "descrizione_deposito",
                header: "Descrizione Deposito",
                enableColumnOrdering: true,
                enableEditing: true,
                enableSorting: true,
                size: 100,
            },
            {
                accessorKey: "codice_deposito_padre",
                header: "Codice Deposito Padre",
                enableColumnOrdering: true,
                enableEditing: true,
                enableSorting: true,
                size: 100,
            },
            {
                accessorKey: "indirizzo_coordinate",
                header: "Indirizzo Coordinate",
                enableColumnOrdering: true,
                enableEditing: true,
                enableSorting: true,
                size: 100,
            },
            {
                accessorKey: "actions", // Add a unique key for actions column
                header: "Azioni",
                size: 120,
                enableSorting: false,
                enableColumnOrdering: false,
                enableEditing: false,
                enableFiltering: false,
                filterVariant: "none",
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: "flex",
                            gap: "0.5rem",
                            justifyContent: "start",
                            alignItems: "center",
                        }}
                    >
                        <Tooltip arrow placement="top" title="Modifica">
                            <IconButton color="secondary" onClick={() => handleEditRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>

                        <Tooltip arrow placement="top" title="Elimina">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ),
                Filter: () => null,
            },
        ],
        []
    );

    const depositiTable = useMaterialReactTable({
        displayColumnDefOptions: {
            "mrt-row-actions": {
                muiTableHeadCellProps: {
                    align: "center",
                },

                size: 120,
            },
        },
        columns: depositiColumns,
        data: listaDepositi,
        localization: MRT_Localization_IT,
        enableColumnFilterModes: true,
        enableColumnOrdering: true,
        enableGrouping: true,
        enablePinning: true,
        enableRowActions: false,
        initialState: {
            showColumnFilters: false,
            columnVisibility: { id: false },
        },
        paginationDisplayMode: "pages",
        positionToolbarAlertBanner: "bottom",
        muiSearchTextFieldProps: {
            size: "small",
            variant: "outlined",
        },
        muiPaginationProps: {
            color: "secondary",
            rowsPerPageOptions: [10, 20, 30],
            shape: "rounded",
            variant: "outlined",
        },
        muiTableBodyCellFilterProps: ({ column }) => {
            if (column.id === "actions") {
                return { hidden: true }; // Ensure no filter input is rendered
            }
            return {}; // Default behavior for other columns
        },
        muiTableHeadCellProps: ({ column }) => {
            if (column.id === "actions") {
                return { style: { pointerEvents: "none" } }; // Prevent filter interaction
            }
            return {};
        },
        renderTopToolbarCustomActions: ({ depositiTable }) => (
            <Button
                color="secondary"
                onClick={() => setOpenDlgNuovo(true)}
                variant="contained"
                sx={{ color: "white" }}
            >
                Nuovo
            </Button>
        ),
    });

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card
                        sx={{
                            backgroundColor: "white",
                            color: "black",
                            position: "relative",
                            width: "100%",
                        }}
                    >
                        <CardContent>
                            <Typography
                                sx={{ fontSize: 24 }}
                                color="black"
                                gutterBottom
                                textAlign={"center"}
                            >
                                Elenco depositi
                            </Typography>
                            <Divider />
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MaterialReactTable table={depositiTable} />
                            </LocalizationProvider>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Dialog
                        open={openDlgNuovo}
                        onClose={() => setOpenDlgNuovo(false)}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle>Nuovo Deposito</DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Codice Deposito"
                                        variant="outlined"
                                        fullWidth
                                        value={codiceDeposito}
                                        onChange={(e) => setCodiceDeposito(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Descrizione Deposito"
                                        variant="outlined"
                                        fullWidth
                                        value={descrizioneDeposito}
                                        onChange={(e) => setDescrizioneDeposito(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Codice Deposito Padre"
                                        variant="outlined"
                                        fullWidth
                                        value={codiceDepositoPadre}
                                        onChange={(e) => setCodiceDepositoPadre(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Indirizzo Coordinate"
                                        variant="outlined"
                                        fullWidth
                                        value={indirizzoCoordinate}
                                        onChange={(e) => setIndirizzoCoordinate(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleNuovoDeposito}>Salva</Button>
                            <Button onClick={handleAnnullaNuovo}>Annulla</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
                <Grid item xs={12}>
                    <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="md">
                        <DialogTitle>Modifica Deposito</DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Codice Deposito"
                                        variant="outlined"
                                        fullWidth
                                        value={codiceDepositoModifica}
                                        onChange={(e) => setCodiceDepositoModifica(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Descrizione Deposito"
                                        variant="outlined"
                                        fullWidth
                                        value={descrizioneDepositoModifica}
                                        onChange={(e) => setDescrizioneDepositoModifica(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Codice Deposito Padre"
                                        variant="outlined"
                                        fullWidth
                                        value={codiceDepositoPadreModifica}
                                        onChange={(e) => setCodiceDepositoPadreModifica(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Indirizzo Coordinate"
                                        variant="outlined"
                                        fullWidth
                                        value={indirizzoCoordinateModifica}
                                        onChange={(e) => setIndirizzoCoordinateModifica(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button type="contained" onClick={handleCloseEdit}>
                                Annulla
                            </Button>
                            <Button type="contained" onClick={handleModificaUtente}>
                                Salva
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        </>
    );
};

export default Depositi;
