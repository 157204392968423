import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import AuthService from "../services/auth.service";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Snackbar,
    TextField,
    ThemeProvider,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import {
    getListaUtenti,
    itemUtente,
    inserisciUtente,
    modificaUtente,
    eliminaUtente,
    cambiaPassword,
} from "../services/UtentiService";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_IT } from "material-react-table/locales/it";
import { Delete, Edit } from "@mui/icons-material";
import PasswordIcon from "@mui/icons-material/Password";
// SweetAlert - 20/06/2024
import Swal from 'sweetalert2'

const Utenti = () => {
    console.log("sono in utenti");
    const [listaUtenti, setListaUtenti] = useState([]);
    const [utenteSelezionato, setUtenteSelezionato] = useState({});
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [open, setOpen] = useState(false); // per lo snackbar
    const [message, setMessage] = useState(""); // messaggio snackbar
    const [severity, setSeverity] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    // per il nuovo ed il cambio password - C. Carlucci 17/08/2023
    const [openDlgNuovo, setOpenDlgNuovo] = useState(false);
    const [openDgPwd, setOpenDlgPwd] = useState(false);
    const [newPasswordRequest, setNewPasswordRequest] = useState({});
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState("md");
    // stati per dialog cambio password - C. Carlucci 18/08/2023
    const [oldusername, setOldUsername] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    // stati per la dialog per l'aggiunta di un nuovo utente - C. Carlucci 18/08/2023
    const [edUsername, setEdUsername] = useState("");
    const [edEmail, setEdEmail] = useState("");
    const [edPassword, setEdPassword] = useState("");
    // aggiungo nome e cognome - C. Carlucci 15/09/2023
    const [edNome, setEdNome] = useState("");
    const [edCognome, setEdCognome] = useState("");

    // New state variables for adding a user
    const [nwUsername, setNwUsername] = useState("");
    const [nwEmail, setNwEmail] = useState("");
    const [nwPassword, setNwPassword] = useState("");
    const [selectedNwRoles, setSelectedNwRoles] = useState([]);
    // Aggiungo nome e cognome - C. Carlucci 15/09/2023
    const [nwNome, setNwNome] = useState("");
    const [nwCognome, setNwCognome] = useState("");

    const ruoli = [
        { name: "Amministratore", key: "admin" },
        { name: "Esportatore", key: "export" },
        { name: "Utente", key: "utente" },
    ];

    const [selectedRuoli, setSelectedRuoli] = useState([]);

    const mapBackendRoleToKey = (backendRole) => {
        switch (backendRole) {
            case "ROLE_AMMINISTRATORE":
                return { name: "Amministratore", key: "admin" };
            case "ROLE_ESPORTATORE":
                return { name: "Esportatore", key: "export" };
            case "ROLE_UTENTE":
            default:
                return { name: "Utente", key: "utente" };

        }
    };

    const onChangeOldPwd = (e) => {
        const oldPassword = e.target.value;
        setOldPassword(oldPassword);
    };

    const onChangeNewPwd = (e) => {
        const newPassword = e.target.value;
        setNewPassword(newPassword);
    };

    // handler per la selezione del ruolo utente - C. Carlucci 18/08/2023
    const onRuoliChange = (event, ruolo) => {
        console.log("sono in onRuoliChange. Ruolo è " + JSON.stringify(ruolo));
        const isChecked = event.target.checked;
        console.log("isChecked contiene " + JSON.stringify(isChecked));
        let _selectedRuoli = [...selectedRuoli];

        if (isChecked) {
            _selectedRuoli.push(ruolo);
        } else {
            _selectedRuoli = _selectedRuoli.filter(
                (selectedRuolo) => selectedRuolo.key !== ruolo.key
            );
        }

        setSelectedRuoli(_selectedRuoli);
    };

    // handler per i ruoli nell'aggiunta utente - C. Carlucci 18/08/2023
    const onNewRuoliChange = (event, ruolo) => {
        const isChecked = event.target.checked;
        let _selectedNwRoles = [...selectedNwRoles];
        if (isChecked) {
            _selectedNwRoles.push(ruolo);
        } else {
            _selectedNwRoles = _selectedNwRoles.filter(
                (selectedRuolo) => selectedRuolo.key !== ruolo.key
            );
        }

        setSelectedNwRoles(_selectedNwRoles);
    };

    useEffect(() => {
        getListaUtenti().then((response) => {
            setListaUtenti(response);
        });
    }, []);

    const handleEditRow = (row) => {
        let id = row.original.id;
        itemUtente(id).then((response) => {
            setUtenteSelezionato((prevState) => ({
                ...prevState,
                ...response,
            }));
            // Extract and map the roles to your custom key names
            const userRoles = response.role.map((role) => mapBackendRoleToKey(role));
            console.log("userRoles contiene " + JSON.stringify(userRoles));
            setSelectedRuoli(userRoles);

            setEdNome(response.nome);
            setEdCognome(response.cognome);
            setEdUsername(response.username);
            setEdEmail(response.email);
            setEdPassword(response.password);

            console.log("utenteSelezionato è " + JSON.stringify(response));
            setOpenDialog(true);
        });
    };

    const handleEditPwd = (row) => {
        let id = row.original.id;
        itemUtente(id).then((response) => {
            setUtenteSelezionato(response);
            console.log("utenteSelezionato è " + JSON.stringify(response));
            setOpenDlgPwd(true);
        });
    };

    const handleModificaUtente = () => {
        let roles = [];
        selectedRuoli.forEach((ruolo) => {
            roles.push(ruolo.key);
        });
        let js = {
            nome: edNome, // Use editedNome instead of utenteSelezionato.nome
            cognome: edCognome, // Use editedCognome instead of utenteSelezionato.cognome
            username: edUsername, // Use editedUsername instead of utenteSelezionato.username
            email: edEmail, // Use editedEmail instead of utenteSelezionato.email
            role: roles,
            password: edPassword, // Use editedPassword instead of utenteSelezionato.password
        };
        console.log("utente passato a put " + JSON.stringify(js));
        modificaUtente(js, utenteSelezionato.id).then((response) => {
            console.log(
                "handleModificaUtente risposta PUT: " + JSON.stringify(response)
            );
            getListaUtenti().then((response) => {
                setListaUtenti(response);
            });
            setOpenDialog(false);
        });
        Swal.fire({
            title: "Utente modificato",
            text: "Il tuo utente è stato modificato con successo!",
            icon: "success",
            confirmButtonText: "Chiudi",
        })
        /*  setSeverity("success");
         setMessage("Utente modificato!");
         setOpen(true); */

    };

    const handleModificaPassword = () => {
        console.log("Sono in modifica password");
        let newpwd = {
            username: utenteSelezionato.username,
            oldPassword: oldPassword,
            newPassword: newPassword,
        };
        console.log("newPwd contiene " + JSON.stringify(newpwd));
        setNewPasswordRequest(newpwd);
        cambiaPassword(newpwd, utenteSelezionato.id).then((response) => {
            console.log("Impostata nuova password");
            setOpenDlgPwd(false);
        });
        /*   setSeverity("success");
          setMessage("Password modificata!");
          setOpen(true); */
        Swal.fire({
            title: "Password modificata",
            text: "La tua password è stata modificata con successo!",
            icon: "success",
            confirmButtonText: "Chiudi",
        })
    };

    const handleCloseEdit = () => {
        setOpenDialog(false);
    };

    const handleClosePwd = () => {
        setOpenDlgPwd(false);
    };

    const columns = useMemo(() => [
        {
            accessorKey: "id",
            header: "ID",
            enableColumnOrdering: false,
            enableEditing: false, //disable editing on this column
            enableSorting: false,
            size: 80,
        },
        {
            accessorKey: "nome",
            header: "Nome",
            enableColumnOrdering: true,
            enableEditing: true,
            enableSorting: true,
            size: 150,
        },
        {
            accessorKey: "cognome",
            header: "Cognome",
            enableColumnOrdering: true,
            enableEditing: true,
            enableSorting: true,
            size: 150,
        },
        {
            accessorKey: "username",
            header: "Username",
            enableColumnOrdering: true,
            enableEditing: true,
            enableSorting: true,
            size: 150,
        },
        {
            accessorKey: "email",
            header: "Email",
            enableColumnOrdering: false,
            enableEditing: true,
            enableSorting: false,
            size: 200,
        },

        {
            accessorFn: (row) => {
                let output = [];
                let r = row.role;
                r.forEach((element) => {
                    output.push(element.split("_")[1]);
                });
                return output.join(", ");
            },
            header: "Ruoli",
            enableColumnOrdering: true,
            enableEditing: true,
            enableSorting: true,
            size: 150,
        },
    ]);

    const handleDeleteRow = useCallback(
        (row) => {
            console.log("row contiene " + JSON.stringify(row));
            if (
                // eslint-disable-next-line no-restricted-globals
                !confirm(
                    `Sei sicuro di voler cancellare l'utente n. ${row.getValue("id")}`
                )
            ) {
                return;
            }
            //send api delete request here, then refetch or update local table data for re-render
            eliminaUtente(row.original.id).then((response) => {
                console.log("response di eliminaUtente " + response);
                /*  setSeverity("success");
                 setMessage("Utente eliminato!"); */
                Swal.fire({
                    title: "Utente eliminato",
                    text: "Il tuo utente è stato eliminato con successo!",
                    icon: "success",
                    confirmButtonText: "Chiudi",
                })
                setOpen(true);
                getListaUtenti().then((response) => {
                    setListaUtenti(response);
                });
            });
        },

        [listaUtenti]
    );

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    // Function to handle addition of a new user
    const handleNuovoUtente = () => {
        const newUser = {
            nome: nwNome,
            cognome: nwCognome,
            username: nwUsername,
            email: nwEmail,
            password: nwPassword,
            role: selectedNwRoles,
        };

        console.log(
            "newUser che invio a inserisciUtente contiene " + JSON.stringify(newUser)
        );

        inserisciUtente(newUser).then((response) => {
            console.log("Nuovo utente creato: ", response);

            setNwNome("");
            setNwCognome("");
            setNwUsername("");
            setNwEmail("");
            setNwPassword("");
            setSelectedNwRoles([]);
            setOpenDlgNuovo(false);
            // Refresh the user list after successful creation
            getListaUtenti().then((response) => {
                setListaUtenti(response);
            });
            /*  setSeverity("success");
             setMessage("Utente aggiunto!");
             setOpen(true); */
            Swal.fire({
                title: "Utente aggiunto",
                text: "Il nuovo utente è stato aggiunto con successo!",
                icon: "success",
                confirmButtonText: "Chiudi",
            })
        });
    };

    const handleAnnullaNuovo = () => {
        setNwNome("");
        setNwCognome("");
        setNwUsername("");
        setNwEmail("");
        setNwPassword("");
        setSelectedNwRoles([]);
        setOpenDlgNuovo(false);
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                        <Alert
                            onClose={handleClose}
                            severity={severity}
                            sx={{ width: "100%" }}
                        >
                            {message}
                        </Alert>
                    </Snackbar>
                </Grid>
                <Grid item xs={12}>
                    <Card
                        sx={{
                            backgroundColor: "white",
                            color: "black",
                            position: "relative",
                            width: "100%",
                        }}
                    >
                        <CardContent>
                            <Typography
                                sx={{ fontSize: 24 }}
                                color="black"
                                gutterBottom
                                textAlign={"center"}
                            >
                                Elenco Utenti
                            </Typography>

                            <MaterialReactTable
                                displayColumnDefOptions={{
                                    "mrt-row-actions": {
                                        muiTableHeadCellProps: {
                                            align: "center",
                                        },

                                        size: 120,
                                    },
                                }}
                                columns={columns}
                                data={listaUtenti}
                                enableColumnFilterModes
                                enableColumnOrdering
                                enableGrouping
                                enablePinning
                                enableRowActions
                                initialState={{ showColumnFilters: false }}
                                positionToolbarAlertBanner="bottom"
                                positionActionsColumn="last"
                                localization={MRT_Localization_IT}
                                renderRowActions={({ row, table }) => (
                                    <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center", }}>
                                        <Tooltip arrow placement="top" title="Modifica">
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleEditRow(row)}
                                            >
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip arrow placement="top" title="Cambia password">
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleEditPwd(row)}
                                            >
                                                <PasswordIcon />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip arrow placement="top" title="Elimina">
                                            <IconButton
                                                color="error"
                                                onClick={() => handleDeleteRow(row)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                                renderTopToolbarCustomActions={() => (
                                    <Button
                                        color="secondary"
                                        onClick={() => setOpenDlgNuovo(true)}
                                        variant="contained"
                                        sx={{ color: "white" }}
                                    >
                                        Nuovo
                                    </Button>
                                )}
                            ></MaterialReactTable>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>

                    <Dialog
                        open={openDialog}
                        onClose={handleCloseEdit}
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                    >
                        <DialogTitle>Utente n. {utenteSelezionato.id}</DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="id"
                                        disabled
                                        defaultValue={utenteSelezionato.id}
                                        label="N. Utente"
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="nome"
                                        value={edNome}
                                        onChange={(e) => setEdNome(e.target.value)}
                                        label="Nome"
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="cognome"
                                        value={edCognome}
                                        onChange={(e) => setEdCognome(e.target.value)}
                                        label="Cognome"
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="username"
                                        value={edUsername}
                                        onChange={(e) => setEdUsername(e.target.value)}
                                        label="Username"
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="email"
                                        value={edEmail}
                                        onChange={(e) => setEdEmail(e.target.value)}
                                        label="Email"
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {selectedRuoli.length > 0 &&
                                        ruoli.map((ruolo) => (
                                            <div key={ruolo.key} className="flex align-items-center">
                                                <Checkbox
                                                    inputId={ruolo.key}
                                                    name="category"
                                                    value={ruolo}
                                                    onChange={(event) => onRuoliChange(event, ruolo)}
                                                    checked={selectedRuoli.some(
                                                        (selectedRuolo) => selectedRuolo.key === ruolo.key
                                                    )}
                                                />
                                                <label htmlFor={ruolo.key} className="ml-2">
                                                    {ruolo.name}
                                                </label>
                                            </div>
                                        ))}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button type="contained" onClick={handleCloseEdit}>
                                Annulla
                            </Button>
                            <Button type="contained" onClick={handleModificaUtente}>
                                Salva
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>
                <Grid item xs={12}>

                    <Dialog
                        open={openDgPwd}
                        onClose={handleClosePwd}
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                    >
                        <DialogTitle>Utente n. {utenteSelezionato.id}</DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="id"
                                        disabled
                                        defaultValue={utenteSelezionato.id}
                                        label="N. Utente"
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="username"
                                        disabled
                                        defaultValue={utenteSelezionato.username}
                                        label="Username"
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="oldpassword"
                                        defaultValue={oldPassword}
                                        onChange={onChangeOldPwd}
                                        label="Vecchia password"
                                        variant="standard"
                                        type="password"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="newpassword"
                                        defaultValue={newPassword}
                                        onChange={onChangeNewPwd}
                                        label="Nuova password"
                                        variant="standard"
                                        type="password"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button type="contained" onClick={handleClosePwd}>
                                Annulla
                            </Button>
                            <Button type="contained" onClick={handleModificaPassword}>
                                Salva
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>
                <Grid item xs={12}>
                    <Dialog
                        open={openDlgNuovo}
                        onClose={() => setOpenDlgNuovo(false)}
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                    >
                        <DialogTitle>Nuovo Utente</DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="newNome"
                                        value={nwNome}
                                        onChange={(e) => setNwNome(e.target.value)}
                                        label="Nome"
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="newCognome"
                                        value={nwCognome}
                                        onChange={(e) => setNwCognome(e.target.value)}
                                        label="Cognome"
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="newUsername"
                                        value={nwUsername}
                                        onChange={(e) => setNwUsername(e.target.value)}
                                        label="Username"
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="newEmail"
                                        value={nwEmail}
                                        onChange={(e) => setNwEmail(e.target.value)}
                                        label="Email"
                                        variant="standard"
                                        fullWidth
                                        multiline
                                        maxRows={4}
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="newPassword"
                                        value={nwPassword}
                                        onChange={(e) => setNwPassword(e.target.value)}
                                        label="Password"
                                        variant="standard"
                                        type="password"
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {ruoli.map((ruolo) => (
                                        <div key={ruolo.key} className="flex align-items-center">
                                            <Checkbox
                                                inputId={ruolo.key}
                                                name="category"
                                                value={ruolo.key}
                                                onChange={(event) => onNewRuoliChange(event, ruolo.key)}
                                                checked={selectedNwRoles.includes(ruolo.key)}
                                            />
                                            <label htmlFor={ruolo.key} className="ml-2">
                                                {ruolo.name}
                                            </label>
                                        </div>
                                    ))}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button type="contained" onClick={handleAnnullaNuovo}>
                                Annulla
                            </Button>
                            <Button type="contained" onClick={handleNuovoUtente}>
                                Salva
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>
            </Grid>

        </>
    )
};

export default Utenti;
