import axios from "axios";
import AuthService from "./auth.service";


// const UTENTI_URL = "http://192.168.1.52:8080/api/v1/utenti"; 

const UTENTI_URL = "http://5.249.157.198:8080/api/v1/utenti";

export const getListaUtenti = async () => {
	const currentUser = AuthService.getCurrentUser();
	// alert(currentUser.token);
	var url = UTENTI_URL;
	try {
		const response = await axios({
			method: "get",
			url: url,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + currentUser.token,
			},
		});
		console.log(
			"getListaUtenti\n" + url + "\nData:" + JSON.stringify(response.data)
		);
		return Promise.resolve(response.data);
	} catch (error) {
		console.error("getListaUtenti:\n" + url + "\n" + error);
		return Promise.reject(error);
	}
};

export const itemUtente = async (id) => {
	const currentUser = AuthService.getCurrentUser();
	var url = UTENTI_URL + "/" + id;
	try {
		const response = await axios({
			method: "get",
			url: url,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + currentUser.token,
			},
		});
		console.log(
			"itemUtente\n" + url + "\nData:" + JSON.stringify(response.data)
		);
		return Promise.resolve(response.data);
	} catch (error) {
		console.error("itemUtente:\n" + url + "\n" + error);
		return Promise.reject(error);
	}
};

export const inserisciUtente = async (data) => {
	const currentUser = AuthService.getCurrentUser();
	// alert(currentUser.token);
	var url = UTENTI_URL;
	try {
		const response = await axios({
			method: "post",
			url: url,
			headers: {
				Authorization: "Bearer " + currentUser.token,
			},
			data: data,
		});
		console.log(
			"inserisciUtente\n" + url + "\nData: " + JSON.stringify(response.data)
		);
		return Promise.resolve(response.data);
	} catch (error) {
		console.error("inserisciUtente:\n" + url + "\n" + error);
		return Promise.reject(error);
	}
};

export const modificaUtente = async (js, id) => {
	console.log("js: " + JSON.stringify(js));
	const currentUser = AuthService.getCurrentUser();
	var url = UTENTI_URL + "/" + id;
	try {
		const response = await axios({
			method: "put",
			url: url,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + currentUser.token,
			},
			data: JSON.stringify(js),
		});
		console.log(
			"modificaUtente\n" + url + "\nData: " + JSON.stringify(response.data)
		);
		return Promise.resolve(response.data);
	} catch (error) {
		console.error("modificaUtente:\n" + url + "\n" + error);
		return Promise.reject(error);
	}
};

export const cambiaPassword = async (js, id) => {
	const currentUser = AuthService.getCurrentUser();
	var url = UTENTI_URL + "/" + id + "/changepassword";
	try {
		const response = await axios({
			method: "put",
			url: url,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + currentUser.token,
			},
			data: JSON.stringify(js),
		});
		console.log(
			"cambiaPassword\n" + url + "\nData: " + JSON.stringify(response.data)
		);
		return Promise.resolve(response.data);
	} catch (error) {
		console.error("cambiaPassword:\n" + url + "\n" + error);
		return Promise.reject(error);
	}
};

export const eliminaUtente = async (id) => {
	const currentUser = AuthService.getCurrentUser();
	var url = UTENTI_URL + "/" + id;
	try {
		const response = await axios({
			method: "delete",
			url: url,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + currentUser.token,
			},
		});
		console.log(
			"eliminaUtente\n" + url + "\nData:" + JSON.stringify(response.data)
		);
		return Promise.resolve(response.data);
	} catch (error) {
		console.error("eliminaUtente:\n" + url + "\n" + error);
		return Promise.reject(error);
	}
};