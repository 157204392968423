import axios from "axios";

// const API_URL = "http://192.168.1.52:8080/api/auth/";
const API_URL = "http://5.249.157.198:8080/api/auth/";


const register = (nome, cognome, username, email, password, role) => {
    return axios.post(API_URL + "signup", {
        nome,
        cognome,
        username,
        email,
        password,
        role,
    });
};

const login = (username, password) => {
    return axios
        .post(API_URL + "signin", {
            username,
            password,
        })
        .then((response) => {
            // alert("response di signin contiene "+JSON.stringify(response));
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            console.log("localStorage contiene " + JSON.stringify(localStorage));
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
};

export default AuthService;