import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import FactoryIcon from "@mui/icons-material/Factory";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const mainNavBarItems = [
  {
    id: 0,
    icon: <PersonSearchIcon />,
    title: 'Utenti',
    route: 'utenti',
    role: 'ROLE_AMMINISTRATORE'
  },
  {
    id: 1,
    icon: <FactoryIcon />,
    title: "Scarichi",
    route: "scarichi",
    role: "TUTTI"
  },
  {
    id: 2,
    icon: <WarehouseIcon />,
    title: "Depositii",
    route: "depositi",
    role: "TUTTI"
  },
  {
    id: 3,
    icon: <InfoIcon />,
    title: "Informazioni",
    route: "informazioni",
    role: "TUTTI"
  },
];
