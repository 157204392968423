import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Card,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Switch,
  ToggleButton,
  Typography,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_IT } from "material-react-table/locales/it";
import { Delete, Edit } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Snackbar,
  SnackbarContent,
  Alert,
  ImageList,
  ImageListItem,
  FormControlLabel,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/it";
import {
  getAllScarichi,
  getItemScarico,
  deleteScarico,
  getAllDepositi,
  modificaScarico,
  settaEsportato,
} from "../services/ScarichiService.js";

import { styled } from "@mui/material/styles";
import { format, parseISO, isValid } from "date-fns"; // date-fns is a popular date formatting library

import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// SweetAlert - 20/06/2024
import Swal from "sweetalert2";
// websocket - 20/06/2024
import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";
// jsPDF per il report degli scarichi, da modello della table. - 09/08/2024
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const Input = styled("input")({
  display: "none",
});

const Scarichi = () => {
  const [elencoScarichi, setElencoScarichi] = useState([]);
  const [scaricoSelezionato, setScaricoSelezionato] = useState({});
  const [open, setOpen] = useState(false); // per lo snackbar
  const [message, setMessage] = useState(""); // messaggio snackbar
  const [severity, setSeverity] = useState("");
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const [openDialog, setOpenDialog] = useState(false);
  // articoli selezionati per export
  const [selectedArticoli, setSelectedArticoli] = useState({});
  // elenco depositi
  const [elencoDepositi, setElencoDepositi] = useState([]);
  const [selectedDeposito, setSelectedDeposito] = useState(""); // State to store selected depositoPartenza for filtering
  const [refreshKey, setRefreshKey] = useState(0);
  const [listaDepositi, setListaDepositi] = useState([]);

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Use SockJS for WebSocket connection
    // const socket = new SockJS("http://192.168.1.52:8080/ws"); // This is the HTTP URL for SockJS
    const socket = new SockJS('http://5.249.157.198:8080/ws'); // This is the HTTP URL for SockJS
    const stompClient = new Client({
      webSocketFactory: () => socket, // Pass the SockJS instance to STOMP client
    });

    stompClient.onConnect = () => {
      console.log("Connected to WebSocket");

      // Subscribe to the topic where messages are sent from backend
      stompClient.subscribe("/topic/scarichi", (message) => {
        const newMessage = JSON.parse(message.body);
        console.log("Received new message:", newMessage);
        newMessage.dataMovimento = new Date(newMessage.dataMovimento);

        setMessages((prevMessages) => [...prevMessages, newMessage]);
      });
    };

    stompClient.activate();

    return () => {
      // Disconnect WebSocket on component unmount or cleanup
      stompClient.deactivate();
    };
  }, []); // Empty dependency array to run once on component mount

  // Function to retrieve descrizioneDeposito from listaDepositi
  const getDescrizioneDeposito = (codiceDeposito) => {
    const deposito = listaDepositi.find(
      (item) => item.value === codiceDeposito
    );
    return deposito ? deposito.label.split(" - ")[1] : ""; // Assuming label format is `${codiceDeposito} - ${descrizioneDeposito}`
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const scarichiResponse = await getAllScarichi();
        setElencoScarichi(scarichiResponse);
        let listaDepositio = [];
        const depositiResponse = await getAllDepositi();
        console.log("Depositi: " + JSON.stringify(depositiResponse));
        depositiResponse.map((deposito) => {
          let item = {
            value: deposito.codice_deposito,
            label: `${deposito.codice_deposito} - ${deposito.descrizione_deposito}`,
          };
          listaDepositio.push(item);
        });
        setListaDepositi(listaDepositio);
        console.log("Lista depositio: " + JSON.stringify(listaDepositio));
        setElencoDepositi(depositiResponse);
      } catch (error) {
        console.error("Errore nel recupero dei dati ", error);
      }
    };

    // Define stompClient in the outer scope so it's accessible for cleanup
    // const socket = new SockJS("http://192.168.1.52:8080/ws"); // SockJS endpoint
    const socket = new SockJS('http://5.249.157.198:8080/ws'); // SockJS endpoint
    const stompClient = new Client({
      webSocketFactory: () => socket,
    });

    const connectWebSocket = () => {
      stompClient.onConnect = () => {
        console.log("Connected to WebSocket");

        // Subscribe to your STOMP destination
        stompClient.subscribe("/topic/scarichi", (message) => {
          const newScarico = JSON.parse(message.body);
          console.log("Received new scarico:", newScarico);
          newScarico.dataMovimento = new Date(newScarico.dataMovimento);
          // Update the state based on the new message
          setElencoScarichi((prevElenco) => {
            if (newScarico.deleted) {
              // Filter out the deleted scarico by ID
              return prevElenco.filter(
                (scarico) => scarico.id !== newScarico.id
              );
            } else {
              // Update existing scarico or add a new one
              const existingIndex = prevElenco.findIndex(
                (scarico) => scarico.id === newScarico.id
              );
              if (existingIndex >= 0) {
                // Update the existing scarico
                const updatedElenco = [...prevElenco];
                updatedElenco[existingIndex] = newScarico;
                return updatedElenco;
              } else {
                // Add the new scarico
                Swal.fire({
                  title: "Nuovo Scarico Ricevuto",
                  text: `Dispositivo: ${newScarico.dispositivo
                    }, Deposito partenza: ${getDescrizioneDeposito(
                      newScarico.depositoPartenza
                    )}`,
                  icon: "info",
                  timer: 3000, // Auto-close after 3 seconds
                  showConfirmButton: false,
                });

                return [...prevElenco, newScarico];
              }
            }
          });
        });
      };

      stompClient.activate();
    };

    // Call functions on component mount
    fetchData();
    connectWebSocket();

    // Cleanup function for WebSocket connection
    return () => {
      stompClient.deactivate();
      console.log("WebSocket connection closed");
    };
  }, [refreshKey]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleDepositoFilter = (value) => {
    setSelectedDeposito(value);
    // Filter elencoScarichi based on selectedDeposito
    // This logic depends on how you structure your data and filter requirements
    const filteredScarichi = elencoScarichi.filter(
      (scarico) => scarico.depositoPartenza === value
    );
    // Update state with filtered scarichi
    setElencoScarichi(filteredScarichi);
  };

  const generatePDF = (righe) => {
    if (!elencoDepositi || elencoDepositi.length === 0) {
      console.error("elencoDepositi is empty or not ready");
      return;
    }

    const doc = new jsPDF("landscape");
    doc.text("Report scarichi del " + new Date().toLocaleDateString(), 20, 10);

    const columns = [
      "Data movimento",
      "Causale",
      "Deposito partenza",
      "Deposito destinazione",
      "Esportato",
    ];

    const rows = righe.map((riga) => {
      // Handle Deposito Partenza
      const partenza =
        elencoDepositi.find(
          (dep) =>
            dep.codiceDeposito.trim().toLowerCase() ===
            (riga.original.depositoPartenza || "").trim().toLowerCase()
        )?.descrizioneDeposito || "N/A";

      // Handle Deposito Destinazione (which can be null)
      const destinazione = riga.original.depositoDestinazione
        ? elencoDepositi.find(
          (dep) =>
            dep.codiceDeposito.trim().toLowerCase() ===
            riga.original.depositoDestinazione.trim().toLowerCase()
        )?.descrizioneDeposito || "N/A"
        : "N/A";

      return [
        new Date(riga.original.data_movimento).toLocaleDateString("it-IT"),
        riga.original.causale,
        partenza,
        destinazione,
        riga.original.esportato ? "Si" : "No",
      ];
    });

    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
      theme: "striped",
      margin: { top: 20 },
      pageBreak: "auto",
    });

    doc.save("scarichi_report_" + new Date().toISOString() + ".pdf");
  };

  const handleDelete = useCallback(async (row) => {
    const id = row.original.id;
    try {
      const result = await Swal.fire({
        title: "Sei sicuro?",
        text: "La cancellazione è definitiva!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      });

      if (result.isConfirmed) {
        const scarico = await getItemScarico(id);
        console.log("selezionato " + JSON.stringify(scarico));

        if (!scarico.esportato) {
          const response = await deleteScarico(id);
          const scarichi = await getAllScarichi();
          setElencoScarichi(scarichi);
          setRefreshKey((prevKey) => prevKey + 1); // Trigger refresh

          await Swal.fire({
            title: "Eliminato!",
            text: response,
            icon: "success",
          });
        } else {
          await Swal.fire({
            title: "Attenzione!",
            text: "Lo scarico risulta già esportato!",
            icon: "warning",
          });
        }
      }
    } catch (error) {
      console.error("Error during deletion:", error);
      await Swal.fire({
        title: "Errore",
        text: "Si è verificato un errore durante l'eliminazione.",
        icon: "error",
      });
    }
  }, []);

  const dateFilterFn = (rows, id, filterValue) => {
    return rows.filter((row) => {
      const cellDate = new Date(row.original[id]);
      const filterDate = new Date(filterValue);
      return cellDate >= filterDate;
    });
  };

  const icon = L.icon({
    iconRetinaUrl: iconRetina,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
  });

  // colonne tabella articoli
  const colsarticoli = useMemo(() => [
    {
      accessorKey: "id",
      header: "ID",
      enableColumnOrdering: false,
      enableEditing: false, //disable editing on this column
      enableSorting: false,
      size: 40,
    },
    {
      accessorKey: "codice",
      header: "Codice",
      enableColumnOrdering: true,
      enableEditing: true,
      enableSorting: true,
    },
    {
      accessorKey: "descrizione",
      header: "Descrizione",
      enableColumnOrdering: true,
      enableEditing: true,
      enableSorting: true,
    },
    {
      accessorKey: "quantita",
      header: "Quantità",
      enableColumnOrdering: true,
      enableEditing: true,
      enableSorting: true,
    },
    {
      accessorKey: "valore",
      header: "Valore",
      enableColumnOrdering: true,
      enableEditing: true,
      enableSorting: true,
    },
  ]);

  // colonne tabella scarichi
  const columns = useMemo(() => [
    {
      accessorKey: "id",
      header: "ID",
      enableColumnOrdering: false,
      enableEditing: false, //disable editing on this column
      enableSorting: false,
      size: 40,
    },
    {
      accessorKey: "data_movimento",
      header: "Data movimento",
      enableColumnOrdering: true,
      enableEditing: true,
      enableSorting: true,
      filterVariant: "date",
      size: 150,
      Cell: ({ cell }) => {
        const dateValue = cell.getValue();
        console.log("Date Value:", dateValue);
        if (dateValue) {
          const date = parseISO(dateValue); // Parse ISO date string to a Date object
          if (isValid(date)) {
            // Check if the parsed date is valid
            return format(date, "dd/MM/yyyy"); // Format the date to your desired format
          }
        }
        return "Invalid Date";
      },
      Filter: ({ column }) => {
        const [filterValue, setFilterValue] = useState("");
        return (
          <TextField
            type="date"
            value={filterValue}
            onChange={(e) => {
              setFilterValue(e.target.value);
              column.setFilterValue(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      },
      customFilterFn: dateFilterFn,
    },
    {
      accessorKey: "causale",
      header: "Causale",
      enableColumnOrdering: true,
      enableEditing: true,
      enableSorting: true,
      size: 150,
    },
    {
      accessorKey: "depositoPartenza",
      header: "Deposito partenza",
      enableColumnOrdering: true,
      enableEditing: true,
      enableSorting: true,
      size: 200,
      filterVariant: "select",
      filterSelectOptions: listaDepositi,
      Cell: ({ cell }) => {
        console.log("Deposito partenza:", cell.getValue());
        const deposito = elencoDepositi.find(
          (dep) => dep.codice_deposito === cell.getValue()
        );
        return deposito ? deposito.descrizione_deposito : "";
      },
    },
    {
      accessorKey: "depositoDestinazione",
      header: "Deposito destinazioe",
      enableColumnOrdering: true,
      enableEditing: true,
      enableSorting: true,
      size: 200,
      filterVariant: "select",
      filterSelectOptions: listaDepositi,
      Cell: ({ cell }) => {
        const deposito = elencoDepositi.find(
          (dep) => dep.codice_deposito === cell.getValue()
        );
        return deposito ? deposito.descrizione_deposito : "";
      },
    },
    {
      accessorKey: "esportato",
      header: "Esportato",
      enableColumnOrdering: true,
      enableEditing: true,
      enableSorting: true,
      filterVariant: "select",
      size: 150,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return value ? "Si" : "No";
      },
      Filter: ({ column }) => {
        const [value, setValue] = useState(""); // Manage the state of the Select component

        useEffect(() => {
          // Set the value to "Tutti" once the component mounts
          setValue("Tutti");
        }, []); // Run only once when the component mounts

        const handleChange = (e) => {
          const selectedValue = e.target.value;
          setValue(selectedValue); // Update local state
          column.setFilterValue(selectedValue); // Update column filterValue
        };

        return (
          <Select
            value={value} // Set value prop to local state
            onChange={handleChange} // Handle change event
            displayEmpty
            sx={{ width: "150px" }}
          >
            <MenuItem value="Tutti">Tutti</MenuItem> {/* Option to show all */}
            <MenuItem value="Si">Si</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        );
      },
      // Custom filter function
      filterFn: (row, columnId, filterValue) => {
        const cellValue = row.getValue(columnId);
        // Compare the filter value ("Si" or "No") with the boolean representation
        if (filterValue === "Tutti") {
          return true; // Show all items
        }
        if (filterValue === "Si") {
          return cellValue === true;
        }
        if (filterValue === "No") {
          return cellValue === false || cellValue === null; // Consider null as "No"
        }
        return true; // Default case to show all if no filter is applied
      },
    },
    {
      accessorKey: "actions", // Add a unique key for actions column
      header: "Azioni",
      size: 120,
      enableSorting: false,
      enableColumnOrdering: false,
      enableEditing: false,
      enableFiltering: false,
      filterVariant: "none",
      Cell: ({ row }) => (
        <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "start" }}>
          <Tooltip arrow placement="top" title="Modifica">
            <IconButton color="secondary" onClick={() => handleEdit(row)}>
              <InfoIcon />
            </IconButton>
          </Tooltip>

          <Tooltip arrow placement="top" title="Elimina">
            <IconButton color="error" onClick={() => handleDelete(row)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      Filter: () => null,
    },
  ]);

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    filename: "Scarico magazzino n. " + scaricoSelezionato.id, // export file name (without .csv)
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportRows = (rows) => {
    console.log("rows contiene: " + JSON.stringify(rows));
    // Map over rows to extract original Scarico and articoli data
    const scarico = scaricoSelezionato; // Assuming scarico is the main object

    // Create an array of transformed objects for each articolo
    const transformedData = rows
      .map((row) => ({
        data_movimento: scarico.data_movimento,
        causale: scarico.causale,
        codice: row.original.codice,
        depositoPartenza: String(scarico.depositoPartenza).padStart(3, "0"), // Ensure 3-digit format with leading zeros
        depositoDestinazione: scarico.depositoDestinazione
          ? String(scarico.depositoDestinazione).padStart(3, "0")
          : "", // Ensure 3-digit format with leading zeros, handle empty case
        quantita: row.original.quantita,
        valore: row.original.valore || "", // Handle empty valore
        numero_documento: scarico.numero_documento || "", // Handle empty numero_documento
        data_documento: scarico.data_documento || "", // Handle empty data_documento
      }))
      .flat(); // Flatten the array of arrays into a single array of objects

    // Convert transformedData to CSV
    const csv = generateCsv(csvConfig)(transformedData);
    /* // prima del download devo settare lo stato dello scarico su esportato - C. Carlucci 26/06/2024
    modificaScarico(scarico.id, true).then((response) => {
      console.log("Scarico aggiornato con successo");
    }); */
    download(csvConfig)(csv);
  };

  const handleExportData = async () => {
    try {
      const scarico = scaricoSelezionato; // Assuming scaricoSelezionato is your selected Scarico object
      const articoli = scarico.articoli; // Assuming articoli is an array of articoli objects

      if (!scarico || !articoli || articoli.length === 0) {
        Swal.fire({
          icon: "warning",
          title: "Nessun dato da esportare",
          text: "Seleziona uno scarico valido con articoli.",
        });
        return;
      }
      // Transform data to include required fields for CSV
      const transformedData = articoli.map((articolo) => ({
        data_movimento: scarico.data_movimento,
        causale: scarico.causale,
        codice: articolo.codice,
        depositoPartenza: String(scarico.depositoPartenza).padStart(3, "0"), // Ensure 3-digit format with leading zeros
        depositoDestinazione: scarico.depositoDestinazione
          ? String(scarico.depositoDestinazione).padStart(3, "0")
          : "", // Ensure 3-digit format with leading zeros, handle empty case
        quantita: articolo.quantita,
        valore: articolo.valore || "", // Handle empty valore
        numero_documento: scarico.numero_documento || "", // Handle empty numero_documento
        data_documento: scarico.data_documento || "", // Handle empty data_documento
      }));

      // Convert transformedData to CSV
      const csv = generateCsv(csvConfig)(transformedData);
      // prima del download devo settare lo stato dello scarico su esportato - C. Carlucci 26/06/2024
      await settaEsportato(scarico.id, true);
      const updatedScarichi = await getAllScarichi();

      setElencoScarichi(updatedScarichi);
      setRefreshKey((prevKey) => prevKey + 1);
      // Trigger the download
      download(csvConfig)(csv);
    } catch (error) {
      console.error("Errore nell'esportazione dei dati:", error);
      Swal.fire({
        icon: "error",
        title: "Errore durante l'esportazione",
        text: "Si è verificato un errore durante l'esportazione dei dati.",
      });
    }
  };

  const tableArticoli = useMaterialReactTable({
    columns: colsarticoli,
    data: scaricoSelezionato.articoli ?? [],
    localization: MRT_Localization_IT,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enablePinning: true,
    enableRowActions: false,
    enableRowSelection: true,
    initialState: {
      showColumnFilters: false,
      columnVisibility: { id: false },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Esporta tutti
        </Button>
        <Button
          disabled={
            !tableArticoli.getIsSomeRowsSelected() &&
            !tableArticoli.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() =>
            handleExportRows(tableArticoli.getSelectedRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Esporta selezionati
        </Button>
      </Box>
    ),
  });

  const table = useMaterialReactTable({
    columns: columns,
    data: elencoScarichi,
    localization: MRT_Localization_IT,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enablePinning: true,
    enableRowActions: false,
    initialState: {
      showColumnFilters: false,
      columnVisibility: { id: false },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
    },
    muiTableBodyCellFilterProps: ({ column }) => {
      if (column.id === "actions") {
        return { hidden: true }; // Ensure no filter input is rendered
      }
      return {}; // Default behavior for other columns
    },
    muiTableHeadCellProps: ({ column }) => {
      if (column.id === "actions") {
        return { style: { pointerEvents: "none" } }; // Prevent filter interaction
      }
      return {};
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        color="secondary"
        onClick={() => generatePDF(table.getFilteredRowModel().rows)}
        variant="contained"
        sx={{ color: "white" }}
      >
        Report
      </Button>
    ),
  });

  const handleEdit = (row) => {
    let id = row.original.id;
    getItemScarico(id).then((response) => {
      setScaricoSelezionato(response);
      setOpenDialog(true);
    });
  };

  const handleCloseEdit = () => {
    setOpenDialog(false);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setScaricoSelezionato((scaricoSelezionato) => ({
      ...scaricoSelezionato,
      [name]: value,
    }));
  };

  const handleDepositoSelect = (e) => {
    const codice = e.target.value;
    const deposito = elencoDepositi.find(
      (dep) => dep.codiceDeposito === codice
    );
    console.log("deposito selezionato : " + JSON.stringify(deposito));
    setSelectedDeposito(deposito);
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              backgroundColor: "white",
              color: "black",
              position: "relative",
              width: "100%",
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 24 }}
                color="black"
                gutterBottom
                textAlign={"center"}
              >
                Elenco movimenti di scarico
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MaterialReactTable table={table} />
              </LocalizationProvider>
            </CardContent>
          </Card>
        </Grid>

        {/*    <div>
          <h1>WebSocket Messages:</h1>
          <ul>
            {messages.map((message, index) => (
              <li key={index}>{JSON.stringify(message)}</li>
            ))}
          </ul>
        </div> */}
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleCloseEdit}
        aria-labelledby="form-dialog-title"
        maxWidth={maxWidth}
        fullWidth={fullWidth}
      >
        <DialogTitle>
          Dettaglio scarico n. {scaricoSelezionato.id} del{" "}
          {scaricoSelezionato.data_movimento
            ? isValidDate(new Date(scaricoSelezionato.data_movimento))
              ? format(
                new Date(scaricoSelezionato.data_movimento),
                "dd/MM/yyyy"
              )
              : "Invalid date"
            : "No date"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                <TextField
                  fullWidth
                  required
                  type="date"
                  label="Data movimento"
                  name="data_movimento"
                  value={scaricoSelezionato.data_movimento}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                <TextField
                  fullWidth
                  required
                  label="Causale"
                  name="causale"
                  value={scaricoSelezionato.causale}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                <FormControl fullWidth required>
                  <InputLabel>Deposito partenza</InputLabel>
                  <Select
                    name="depositoPartenza"
                    value={scaricoSelezionato.depositoPartenza || ""}
                    onChange={handleDepositoSelect}
                    disabled={true}
                  >
                    {elencoDepositi.map((dep) => (
                      <MenuItem key={dep.codice_deposito} value={dep.codice_deposito}>
                        {dep.descrizione_deposito} {/* Adjust according to your object structure */}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                <FormControl fullWidth>
                  <InputLabel>Deposito destinazione</InputLabel>
                  <Select
                    name="depositoDestinazione"
                    value={scaricoSelezionato.depositoDestinazione || ""}
                    onChange={handleDepositoSelect}
                    disabled={true}
                  >
                    {elencoDepositi.map((dep) => (
                      <MenuItem key={dep.codice_deposito} value={dep.codice_deposito}>
                        {dep.descrizione_deposito}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                <TextField
                  fullWidth
                  label="Numero documento"
                  name="numero_documento"
                  value={scaricoSelezionato.numero_documento ?? ""}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                <TextField
                  fullWidth
                  type="date"
                  label="Data documento"
                  name="data_documento"
                  value={scaricoSelezionato.data_documento ?? ""}
                  onChange={handleFormChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                <TextField
                  fullWidth
                  label="Esportato"
                  name="esportato"
                  value={
                    scaricoSelezionato.esportato === true
                      ? "Esportato"
                      : "Non esportato"
                  }
                  onChange={handleFormChange}
                  inputProps={Input}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              {scaricoSelezionato.latitudine !== null &&
                scaricoSelezionato.longitudine !== null && (
                  <MapContainer
                    center={[
                      scaricoSelezionato.latitudine,
                      scaricoSelezionato.longitudine,
                    ]}
                    zoom={13}
                    scrollWheelZoom={false}
                    fadeAnimation={true}
                    markerZoomAnimation={true}
                    bounds={[
                      scaricoSelezionato.latitudine,
                      scaricoSelezionato.longitudine,
                    ]}
                    style={{
                      marginTop: "23px",
                      height: "95%",
                    }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                      icon={icon}
                      position={[
                        scaricoSelezionato.latitudine,
                        scaricoSelezionato.longitudine,
                      ]}
                    >
                      <Popup>
                        {scaricoSelezionato.data_movimento} <br />{" "}
                        {scaricoSelezionato.causale}
                      </Popup>
                    </Marker>
                  </MapContainer>
                )}
              {(scaricoSelezionato.latitudine === null ||
                scaricoSelezionato.longitudine === null) && (
                  <Typography variant="body2">
                    Latitudine e longitudine non disponibili.
                  </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: 24 }}
                color="black"
                gutterBottom
                textAlign={"center"}
              >
                Articoli
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MaterialReactTable table={tableArticoli} />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Scarichi;
